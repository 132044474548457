import { AuthGuard } from './guards/auth.guard';
import { Routes } from '@angular/router';

// Control variable to decide which initial route will be loaded
const route: string = window.localStorage.getItem('userData') ? 'main' : 'home';
export const routes: Routes = [
  {
    path: '',
    redirectTo: route,
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadComponent: () => import('./main/main.page').then((m) => m.MainPage),
  },
  {
    path: '**',
    redirectTo: route,
    pathMatch: 'full',
  },
];
