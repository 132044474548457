import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthService } from '../app/services/auth/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Auth service, used to refresh the token
   */
  authService: AuthService | undefined;

  constructor(private router: Router, private inj: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // We should get the AuthService like this to avoid circular dependencies
    this.authService = this.inj.get(AuthService);

    request = request.clone({
      withCredentials: true,
    });

    // Handle the request
    return next.handle(request).pipe(
      catchError((error: any) => {
        // If the token is expired...
        if (
          error.statusText == 'Unknown Error' &&
          error.url == `${environment.apiDomain}/mobile/workday/check`
        ) {
          // If session is expired, redirect to log in
          this.authService?.logout();
          this.router.navigate(['home']);
        }

        return observableThrowError(error);
      })
    );
  }
}
