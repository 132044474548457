import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { SessionService } from '../services/session/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private session: SessionService
  ) {}

  canActivate(): boolean {
    if (!this.auth.isLoggedIn && this.session.getCurrentUser() == null) {
      this.router.navigate(['/home']);

      return false;
    }

    return true;
  }
}
