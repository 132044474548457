import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;

  /**
   * Remove userData from browser storage and redirects the user to login page
   */
  logout(): void {
    this.isLoggedIn = false;
    window.localStorage.removeItem('userData');
  }

  /**
   * Whether the user has a valid token or not
   * @returns {boolean}
   */
  loggedIn(): boolean {
    this.isLoggedIn = true;

    return !!window.localStorage.getItem('userData');
  }
}
