import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponseInterface } from '../../../interfaces/login-response.interface';
import { UserInterface } from '../../../interfaces/user.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private userData: UserInterface;

  constructor(private http: HttpClient) {
    this.userData = window.localStorage.getItem('userData')
      ? JSON.parse(window.localStorage.getItem('userData'))
      : null;
  }

  /**
   * userData getter
   * @return userData
   */
  getCurrentUser(): UserInterface {
    return this.userData;
  }

  /**
   * userData setter
   * It also set the localStorage variable to maintain the session
   * @param data
   * @private
   */
  private setUserData(data: UserInterface): void {
    this.userData = data;
    window.localStorage.setItem('userData', JSON.stringify(data));
  }

  /**
   * Log in function. If post's call to the backend endpoint is correct, the user is set with the email and pass params
   * @param email
   * @param password
   * @return return the asynchronous response of the post's call
   */
  logIn(email: string, password: string): Observable<LoginResponseInterface> {
    this.userData = null;

    //Call to the endpoint with the needed data (email and password)
    return (
      this.http
        .post(
          `${environment.apiDomain}/mobile/login_email`,
          {
            email,
            password,
          },
          { withCredentials: true }
        )
        // Manage the response returned by the call
        .pipe(
          map((response: LoginResponseInterface) => {
            if (response && response.login_ok) {
              if (response.user) {
                this.setUserData(response.user);
              }
            }

            return response;
          })
        )
    );
  }

  /**
   * Function to destroy the userData variable and remove the item saved in localStorage
   * @private
   */
  private destroySession() {
    this.userData = null;
    window.localStorage.removeItem('userData');
  }

  /**
   * Log out function. Besides destroying session, calls to the respective endpoint
   */
  logOut(): void {
    try {
      this.http.post(`${environment.apiDomain}/mobile/logout`, {});
    } catch (error) {
      console.log(error);
    }
    this.destroySession();
  }
}
